.my_default_button {
  font-size: 16px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-shadow: none;
  border-style: none;
  width: fit-content;
  height: 44px;
  border-radius: 8px;
  box-shadow: none;
  padding: 0 16px;
  line-height: 24px;
  display: flex;
  &.fullWidth {
    width: 100%;
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-btn-icon {
    overflow: visible;
  }
  &.ant-btn-sm,
  &.ant-btn-lg {
    padding: 0 15px;
  }
  .anticon {
    display: flex;
  }
  .ant-btn-loading-icon {
    display: flex;
  }
  &.my_default_button_primary {
    background-color: var(--mainColor);
    &:hover {
      background-color: var(--mainColor);
    }
    &:disabled {
      background-color: var(--mainColorLighter);
      color: var(--white);
    }
    .anticon-loading {
      color: var(--white);
    }
  }
  &.my_default_button_radius {
    background-color: var(--mainColor);
    border-radius: 8px;
    color: var(--white);
    &:hover {
      background-color: var(--mainColor);
    }
    &:disabled {
      background-color: var(--mainColorLighter);
      color: var(--white);
    }
    .anticon-loading {
      color: var(--white);
    }
  }
  &.my_default_button_white {
    background-color: var(--white);
    font-size: 14px;
    font-weight: 500;
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
    &:disabled {
      opacity: 0.5;
      &:hover {
        background-color: var(--white);
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
      }
    }
    &:hover {
      background-color: var(--white);
    }
  }
  &.my_default_button_cancel {
    background-color: var(--white);
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    font-weight: 500;
    border: 1px solid rgba(229, 233, 242, 1);
    gap: 6px;
    &:hover {
      background-color: var(--lightgrey);
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  &.my_default_button_danger {
    background: var(--red);
    border-color: transparent;
    color: var(--white);
  }
  &.my_default_button_gmail {
    background-color: #dc4a38;
    color: var(--white);
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        background-color: #dc4a38;
        color: var(--white);
      }
    }
  }
  &.my_default_button_red {
    background-color: rgba(241, 43, 44, 0.15);
    color: var(--red);
    &:disabled {
      opacity: 0.5;
      &:hover {
        background-color: rgba(241, 43, 44, 0.15);
        color: var(--red);
      }
    }
  }
  &.my_default_button_microsoft {
    background-color: #0072c6;
    color: var(--white);
    &:disabled {
      opacity: 0.5;
      &:hover {
        background-color: #0072c6;
        color: var(--white);
      }
    }
  }
  &.my_default_button_facebook {
    background-color: #4c69ba;
    color: var(--white);
  }
  &.my_default_button_big {
    height: 54px;
    font-size: 16px;
  }
  &.my_default_button_small {
    height: 32px;
  }
  // + .my_default_button {
  //   margin-left: 20px;
  // }
}

.my_default_button_primary:not(:disabled):not(.my_default_button_disabled):hover {
  background-color: var(--mainColor);
}

.my_default_button.ant-btn-sm.my_default_button_radius,
.my_default_button.ant-btn-lg.my_default_button_radius {
  border-radius: 8px;
}

[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.kep-login-facebook {
  align-items: center;
  border-radius: 8px !important;
  border-style: none !important;
  box-shadow: none;
  display: flex !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  height: 40px;
  justify-content: center;
  line-height: normal;
  padding: 0 15px !important;
  text-shadow: none;
  text-transform: capitalize !important;
  width: fit-content;
}
